






















import Vue from 'vue'
import { createCategoryPostTree } from '@/helper'
import CategoryTreeNode from '../../models/CategoryTreeNode'
import CategoryPostTree from './CategoryPostTree.vue'

// enum ListType {
//   CATEGORY = 'category',
//   SUB_CATEGORY = 'subCategory',
//   POST = 'post'
// }

export default Vue.extend({
  props: {
    categories: {
      type: Array
    },
    parentCategory: {
      type: String
    },
    completedPosts: {
      type: Set,
      default: () => new Set()
    }
  },
  components: {
    CategoryPostTree
  },
  computed: {
    categoryTree(): CategoryTreeNode[] {
      return createCategoryPostTree(
        this.parentCategory || null,
        this.categories
      )
    }
  },
  data() {
    return {}
  }
})
