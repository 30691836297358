











import Vue from 'vue'
import Shimmer from './Shimmer.vue'
export default Vue.extend({
  components: {
    Shimmer
  }
})
