









































































































































































































import Vue from 'vue'
import DropDown from '@/components/ui-components/DropDown.vue'
import config from '@/config/index'
import axios from 'axios'
import Payment from '@/components/checkout/Payment.vue'
import { countries } from '@/helper/constants'
import { OfferType } from '../../models/Offer'
import CheckoutService from '../../services/CheckoutService'
import { SERVICE_AGREEMENT } from '../../models/OfferCustomization'
declare global {
  interface Window {
    libphonenumber: any
  }
}

/* eslint-disable @typescript-eslint/camelcase */

export default Vue.extend({
  components: { DropDown, Payment },
  props: { offer: Object, builder: String, location: Object },
  data() {
    const countryList = Object.keys(countries).map(code => {
      return { value: code, label: countries[code] }
    })
    OfferType
    SERVICE_AGREEMENT
    return {
      OfferType,
      SERVICE_AGREEMENT,
      countryList,
      acceptServiceAgreement: false,
      selectedMenuItem: '',
      email: '',
      fullName: '',
      phoneNumber: '',
      addressLine: '',
      city: '',
      selectedCountry: 'US',
      zipCode: '',
      state: '',
      locationCode: 'US', // TODO: fetch from api,
      invalidPhone: false,
      optin: true,
      disableSubmitBtn: false,
      contact: {} as { [key: string]: any },
      showThankYouMsg: false,
      errMsg: '',
      cToken: '',
      url: '',
      offerAlreadyPurchased: false
    }
  },
  metaInfo() {
    return {
      script: [
        {
          src: 'https://js.stripe.com/v3/',
          async: true,
          type: 'text/javascript'
        },
        {
          src:
            'https://unpkg.com/libphonenumber-js@1.7.31/bundle/libphonenumber-min.js',
          async: true,
          type: 'text/javascript'
        }
      ]
    }
  },

  computed: {
    showCheckoutForm(): boolean {
      return !this.showThankYouMsg && !this.offerAlreadyPurchased
    },
    showPaymentForm(): boolean {
      return (
        !this.optin &&
        !this.showThankYouMsg &&
        !this.freeProduct &&
        !this.offerAlreadyPurchased
      )
    },
    showServiceAgreement() {
      return (
        this.offer.OfferCustomization &&
        this.offer.OfferCustomization.serviceAgreement !==
          SERVICE_AGREEMENT.NOT_REQUIRED
      )
    },
    freeProduct() {
      return this.offer.type === OfferType.free
    },
    disableActions(): object {
      return {
        pointerEvents: this.builder || !this.optin ? 'none' : 'all'
      }
    },
    formattedPhoneNumber: {
      get(): string {
        if (!this.phoneNumber) return ''
        try {
          const parsedNumber = window.libphonenumber.parsePhoneNumberFromString(
            this.phoneNumber,
            this.location.country
          )
          if (
            parsedNumber &&
            parsedNumber.isValid() &&
            this.location.country === parsedNumber.country
          ) {
            return window.libphonenumber
              .parsePhoneNumberFromString(
                this.phoneNumber,
                this.location.country
              )
              .formatNational()
          } else {
            return parsedNumber.formatInternational()
          }
        } catch (error) {
          return this.phoneNumber
        }
      },
      set(phone: string) {
        try {
          const parsedNumber = window.libphonenumber.parsePhoneNumberFromString(
            phone,
            this.location.country
          )
          this.phoneNumber = parsedNumber.number
        } catch (error) {
          this.phoneNumber = phone
        }
      }
    },
    locationId(): string {
      return this.offer.locationId
    }
  },
  methods: {
    orderConfirmation({ ct, previewUrl }) {
      this.showThankYouMsg = true
      this.cToken = ct
      this.url = previewUrl
    },
    goToPreview() {
      if (this.cToken) {
        if (location.hostname === 'localhost') {
          window.open(
            `http://localhost:4040/library/?domain=${this.url}&token=${this.cToken}&location_id=${this.contact.location_id}`
          )
        } else {
          window.open(
            `${this.url}/library?&token=${this.cToken}&location_id=${this.contact.location_id}`
          )
        }
      }
    },
    async createContact() {
      this.disableSubmitBtn = true
      this.errMsg = ''
      const params = {
        location_id: this.locationId,
        name: this.fullName,
        phone: this.phoneNumber,
        email: this.email.toLowerCase(),
        address1: this.addressLine,
        lead: true,
        country: this.offer.OfferCustomization?.addressRequired
          ? this.selectedCountry
          : '',
        state: this.state,
        city: this.city,
        postalCode: this.zipCode,
        source: 'membership',
        offerId: this.offer.id
      }
      await axios
        .post(config.mainServerUrl + '/contact/membership', params)
        .then(async response => {
          this.contact = response.data

          const {
            data: { purchased, loginUrl }
          } = await CheckoutService.checkIfContactPurchasedOffer({
            contactId: this.contact.id,
            offerId: this.offer.id,
            locationId: this.locationId
          })

          this.offerAlreadyPurchased = purchased
          if (this.offerAlreadyPurchased) {
            this.url = loginUrl
            return
          }

          await this.createLeadEntry()
          if (this.offer.type === OfferType.free) {
            await this.createFeePurchase()
          }
          this.disableSubmitBtn = false
          this.optin = false
        })
        .catch(() => {
          this.errMsg = 'something went wrong, please try again'
          this.disableSubmitBtn = false
        })
    },
    async createFeePurchase() {
      const payload = {
        fingerprint: this.contact.fingerprint,
        locationId: this.contact.location_id,
        productType: 'offer',
        checkoutType: 'free'
      }

      await CheckoutService.checkout(this.$route.params.id, payload)
        .then(response => {
          this.showThankYouMsg = true
          const { ct, previewUrl } = response.data
          this.cToken = ct
          this.url = previewUrl
        })
        .catch(error => {
          console.error(error)
          const errorMessage = error?.response?.data?.msg
          this.errMsg =
            errorMessage || 'Something went wrong, please try again!'
        })
    },
    async createLeadEntry() {
      const payload = {
        contactId: this.contact.id,
        locationId: this.contact.location_id,
        offerId: this.$route.params.id
      }
      await CheckoutService.leadTrack(payload)
    }
  }
})
