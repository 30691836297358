








































































































import Vue from 'vue'
import LoginService from '../services/LoginService'
import ButtonLoader from '@/components/ui-components/ButtonLoader.vue'

import { isMobileTablet, deviceId } from '../helper/device_info'
import { initialize, saveUserId, saveContactId } from '@/services'
// import { fetchAndSaveBrandData } from '../helper'

export default Vue.extend({
  components: { ButtonLoader },
  data() {
    return {
      email: '',
      password: '',
      deviceType: '',
      deviceId: '',
      rememberMe: false,
      errorMsg: '',
      loader: false
    }
  },
  computed: {
    brandLogoUrl() {
      return this.$store.state.logoUrl
    },
    hostName(): string {
      return this.$route.query.domain || window.location.hostname
    }
  },
  methods: {
    async login() {
      try {
        this.loader = true
        const { data } = await LoginService.login({
          email: this.email.trim(),
          password: this.password.trim(),
          deviceName: navigator.userAgent,
          deviceId: deviceId,
          deviceType: isMobileTablet() ? 'mobile' : 'desktop',
          hostName: this.hostName
        })
        if (data.token) {
          this.errorMsg = ''

          const payload = btoa(JSON.stringify(data))
          if (localStorage) {
            localStorage.setItem('cat', payload)
          }

          if (this.rememberMe) {
            this.$cookies.set('cat', payload, '30d')
          } else {
            this.$cookies.set('cat', payload)
          }

          initialize(data.locationId)
          saveUserId(data.userId)
          saveContactId(data.contactId)

          this.$router.push({ name: 'library' })
        }
      } catch (error) {
        this.errorMsg =
          error.response && error.response.data.msg
            ? error.response.data.msg
            : 'Incorrect username or password'
      } finally {
        this.loader = false
      }
    }
  }
})
