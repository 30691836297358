
























































import Vue from 'vue'
import Form from '@/components/checkout/Form.vue'
import Offer, { OfferType } from '../../models/Offer'
import { currency } from '@/helper/constants'

export default Vue.extend({
  components: { Form },
  props: {
    offer: Object,
    builder: String,
    location: Object
  },
  data() {
    currency
    OfferType
    return {
      currency,
      OfferType
    }
  },
  computed: {
    dueNow(): string {
      if (this.offer.priceDisplayOverride) {
        return this.offer.priceDisplayOverride
      } else if (!this.offer.trialDays) {
        return (
          this.fetchCurrencySymbol(this.offer.currency) +
          (Number(this.offer.amount) + Number(this.offer.setupFee || 0))
        )
      } else {
        return (
          this.fetchCurrencySymbol(this.offer.currency) +
          (this.offer.setupFee || 0)
        )
      }
    }
  },
  methods: {
    fetchCurrencySymbol(currency: string) {
      if (currency) {
        return this.currency[currency].symbol
      }
      return ''
    }
  }
})
