





















































import Vue from 'vue'
import CategoryPostBreadcrumbs from '@/components/common/CategoryPostBreadcrumbs.vue'
import LessonsStats from '@/components/product/LessonsStats.vue'
import InstructorCard from '@/components/product/InstructorCard.vue'
import CategoryListItem from '@/components/product/CategoryListItem.vue'
// import Progress from '@/components/common/Progress.vue'
import Product from '../models/Product'
import Category, { CategoryVisibility } from '../models/Category'
import {
  ProductService,
  CategoryService,
  getUserId,
  UserPostCompletionService,
  UserPurchaseService,
  PostService
} from '../services'
import { Instructor } from '../models/ProductCustomization'
import { extractInstructorInfoFromCustomization } from '../helper'
import { defaultPosterImage, userAvatarImage } from '../helper/constants'
import Post, { PostVisibility } from '../models/Post'
import CategoryShimmer from '@/components/common/CategoryShimmer.vue'

export default Vue.extend({
  props: {
    product: {
      type: Object
    },
    instructor: {
      type: Object
    }
  },
  components: {
    CategoryPostBreadcrumbs,
    LessonsStats,
    InstructorCard,
    // Progress,
    CategoryShimmer,
    CategoryListItem
  },
  computed: {
    productId(): string {
      return this.$route.params.id
    }
  },
  data() {
    return {
      categories: [] as Category[],
      totalPosts: 0,
      completedPosts: 0,
      postIdsCompleted: new Set() as Set<string>,
      defaultPosterImage,
      categoryProgress: {},
      fetching: false
    }
  },
  async mounted() {
    this.fetching = true
    await this.fetchCompletionData()
    await this.fetchCategories()
    await this.fetchCategoryProgress()
    this.fetching = false

    // await this.fetchPublishedPosts()
  },
  methods: {
    async fetchCategories() {
      // const allCategories = await CategoryService.findAll({
      //   product_id: this.productId,
      //   visibility: CategoryVisibility.published
      // })

      const allCategories = await UserPurchaseService.getCategories({
        product_id: this.productId
      })

      // show only root categories
      this.categories = allCategories
        .filter((c: Category) => c.parentCategory === null)
        .sort((a, b) => a.sequenceNo - b.sequenceNo)
    },
    // async fetchPublishedPosts() {
    //   let noOfPublishedPosts = 0

    //   const fetchingPublishedPosts = this.categories.map(
    //     async (c: Category) => {
    //       const posts = await PostService.findAll({
    //         visibility: PostVisibility.published,
    //         category_id: c.id
    //       })

    //       noOfPublishedPosts += posts.length

    //       return { categoryId: c.id, posts }
    //     }
    //   )

    //   const publishedPosts = await Promise.all(fetchingPublishedPosts)
    //   this.totalPosts = noOfPublishedPosts
    //   console.log('published posts data --> ', publishedPosts)

    //   const categoryProgress = publishedPosts.reduce(
    //     (agg: object, { categoryId, posts }) => {
    //       const totalPostsInCategory = posts.length
    //       const noOfPostsCompleted = posts.filter((post: Post) =>
    //         this.postIdsCompleted.has(post.id)
    //       ).length

    //       const completionPercentage = Math.round(
    //         (noOfPostsCompleted / totalPostsInCategory) * 100
    //       )

    //       const newAgg = {}
    //       newAgg[categoryId] = completionPercentage

    //       return { ...agg, ...newAgg }
    //     },
    //     {}
    //   )

    //   this.categoryProgress = categoryProgress
    // },
    async fetchCompletionData() {
      await this.fetchCompletedPosts()
      await this.fetchNoOfPublishedPosts()
    },
    async fetchCompletedPosts() {
      const userId = getUserId()

      const completedResp = await UserPostCompletionService.findAll({
        product_id: this.productId,
        user_id: userId
      })

      this.completedPosts = completedResp.length

      this.postIdsCompleted = completedResp.reduce(
        (agg: Set<string>, completed: any) => {
          const { postId } = completed
          agg.add(postId)
          return agg
        },
        new Set()
      )
    },
    async fetchNoOfPublishedPosts() {
      const publishedResp = await PostService.noOfPublishedPosts({
        product_id: this.productId
      })

      this.totalPosts = publishedResp.count
    },
    async fetchCategoryProgress() {
      const categories = this.categories.map((c: Category) => c.id)
      const resp = await CategoryService.fetchCategoriesProgress(
        this.productId,
        categories
      )

      this.categoryProgress = resp.reduce((agg, data) => {
        const { categoryId, progress } = data
        const newProgress = {}
        newProgress[categoryId] = progress

        return { ...agg, ...newProgress }
      }, {})
    }
  }
})
