

































































import Vue from 'vue'
import LoginService from '../../services/LoginService'
export default Vue.extend({
  computed: {
    saveDisabled(): boolean {
      return (
        this.updating ||
        !this.currentPassword ||
        !this.newPassword ||
        !this.verifyPassword
      )
    },
    inputsValid(): boolean {
      return (
        !this.currentPwdInvalid && !this.newPwdInvalid && !this.verifyPwdInvalid
      )
    }
  },
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      verifyPassword: '',
      currentPwdInvalid: false,
      newPwdInvalid: false,
      verifyPwdInvalid: false,
      updating: false,
      showSuccess: false,
      btnText: 'Update',
      errorMessage: '',
      passwordsNotMatch: false
    }
  },
  methods: {
    resetInputs() {
      this.currentPassword = ''
      this.newPassword = ''
      this.verifyPassword = ''
    },
    resetValidCheckers() {
      this.currentPwdInvalid = false
      this.newPwdInvalid = false
      this.verifyPwdInvalid = false
      this.passwordsNotMatch = false
    },
    saveNewPassword() {
      if (!this.currentPassword) {
        this.currentPwdInvalid = true
      }

      if (!this.newPassword) {
        this.newPwdInvalid = true
      }

      if (!this.verifyPassword) {
        this.verifyPwdInvalid = true
      }

      if (this.newPassword !== this.verifyPassword) {
        this.newPwdInvalid = true
        this.verifyPwdInvalid = true
        this.passwordsNotMatch = true
      }

      if (!this.inputsValid) {
        return
      }

      this.updatePassword()
    },
    async updatePassword() {
      try {
        this.errorMessage = ''
        this.updating = true
        this.btnText = 'Updating..'

        const passwordUpdated = await LoginService.updatePassword({
          password: this.currentPassword,
          newPassword: this.newPassword,
          verifyPassword: this.verifyPassword
        })

        this.resetInputs()
        this.btnText = 'Password Updated!'
      } catch (error) {
        console.error('Error while updating password --> ', error)

        this.errorMessage =
          error && error.msg ? error.msg : 'Error while updating password'
      } finally {
        this.updating = false

        setTimeout(() => {
          this.btnText = 'Update'
        }, 2000)
      }
    }
  }
})
