































import Vue from 'vue'
import CategoryPostBreadcrumbs from '@/components/common/CategoryPostBreadcrumbs.vue'
import LessonsStats from '@/components/product/LessonsStats.vue'
import InstructorCard from '@/components/product/InstructorCard.vue'
import CategoryPostList from '@/components/product/CategoryPostList.vue'
import Product from '../models/Product'
import Category, { CategoryVisibility } from '../models/Category'
import { Instructor } from '../models/ProductCustomization'
import {
  ProductService,
  CategoryService,
  getUserId,
  UserPostCompletionService,
  PostService,
  UserPurchaseService
} from '../services'
import { extractInstructorInfoFromCustomization } from '../helper'
import { userAvatarImage } from '../helper/constants'
import CategoryShimmer from '@/components/common/CategoryShimmer.vue'

export default Vue.extend({
  props: {
    product: {
      type: Object
    },
    instructor: {
      type: Object
    }
  },
  components: {
    CategoryPostBreadcrumbs,
    LessonsStats,
    InstructorCard,
    CategoryPostList,
    CategoryShimmer
  },
  computed: {
    productId(): string {
      return this.$route.params.id
    },
    categoryId(): string {
      return this.$route.params.category_id
    }
  },
  data() {
    return {
      category: {} as Category,
      categories: [] as Category[],
      completedPosts: 0,
      postIdsCompleted: new Set() as Set<string>,
      totalPosts: 0,
      fetching: false
    }
  },
  mounted() {
    this.fetchCategory(this.categoryId)
    this.fetchCompletionData()
  },
  methods: {
    async fetchCategory(categoryId: string) {
      this.fetching = true
      // this.category = await CategoryService.findById(categoryId, {
      //   visibility: CategoryVisibility.published,
      //   published_posts: true
      // })

      this.category = await UserPurchaseService.getCategory(categoryId, {
        product_id: this.productId,
        visibility: CategoryVisibility.published,
        published_posts: true
      })

      // const subCategories = await CategoryService.findAll({
      //   parent_category: this.categoryId,
      //   visibility: CategoryVisibility.published,
      //   published_posts: true
      // })
      const subCategories = await UserPurchaseService.getCategories({
        product_id: this.productId,
        parent_category: this.categoryId
      })

      this.fetching = false

      this.categories = [this.category, ...subCategories]
    },
    fetchCompletionData() {
      this.fetchCompletedPosts()
      this.fetchNoOfPublishedPosts()
    },
    async fetchCompletedPosts() {
      const userId = getUserId()

      const completedResp = await UserPostCompletionService.findAll({
        product_id: this.productId,
        user_id: userId
      })

      this.completedPosts = completedResp.length

      this.postIdsCompleted = completedResp.reduce(
        (agg: Set<string>, completed: any) => {
          const { postId } = completed
          agg.add(postId)
          return agg
        },
        new Set()
      )
    },
    async fetchNoOfPublishedPosts() {
      const publishedResp = await PostService.noOfPublishedPosts({
        product_id: this.productId
      })

      this.totalPosts = publishedResp.count
    }
  },
  watch: {
    categoryId(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.fetchCategory(newValue)
      }
    }
  }
})
