var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{staticClass:"w-full cursor-pointer bg-gray-100 rounded p-2",attrs:{"id":"go-to-next-post","tag":"div","to":{
    name: 'post-overview',
    params: {
      id: _vm.post.productId,
      category_id: _vm.post.categoryId,
      post_id: _vm.post.id
    }
  }}},[_c('div',{staticClass:"card-content flex flex-col md:flex-row lg:flex-row md:items-center lg:items-center md:justify-between lg:justify-between"},[_c('div',{staticClass:"image-title-container flex items-center"},[_c('img',{staticClass:"post-image",attrs:{"src":_vm.post.posterImage || _vm.defaultPosterImage,"alt":"Post Image"}}),_c('div',{staticClass:"post-title-container mx-4 py-2 flex-1 text-xs md:text-base lg:text-base"},[_c('p',{staticClass:"my-0 completion-message"},[_vm._v("Awesome! Continue Pushing!")]),_c('h3',{staticClass:"my-0 post-title"},[_vm._v(_vm._s(_vm.post.title))])])]),_c('div',{staticClass:"next-lesson-message text-blue-500 font-bold flex items-center"},[_vm._v(" Next lesson > ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }