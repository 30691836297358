



























import Vue from 'vue'
import { userAvatarImage } from '../../helper/constants'
export default Vue.extend({
  props: {
    instructor: {
      type: Object
    }
  },
  data() {
    return {
      defaultValues: {
        heading: 'Instructor',
        name: 'Instructor Name',
        title: 'Instructor',
        headshot: userAvatarImage,
        bio:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sed sapien quam. Sed dapibus est id enim facilisis, at posuere turpis adipiscing. Quisque sit amet dui dui.'
      }
    }
  }
})
