import Model from './Model'

export enum SERVICE_AGREEMENT {
  NOT_REQUIRED = 'notRequired',
  REQUIRED = 'required',
  CUSTOM = 'custom'
}

export default class OfferCustomization extends Model {
  constructor(payload?: {
    id?: string
    enableBanner?: boolean
    bannerImage?: string
    bannerImageHeight?: string
    logoImage?: string
    videoUrl?: string
    primaryColor?: string
    addressRequired?: boolean
    phoneRequired?: boolean
    namePasswordRequired?: boolean
    serviceAgreement?: boolean
    serviceAgreementText?: string
    serviceAgreementExternalLink?: string
    checkoutCopy?: string
  }) {
    super(payload || {})
  }

  get enableBanner(): boolean {
    return this._data.enableBanner
  }

  set enableBanner(enableBanner: boolean) {
    this._data.enableBanner = enableBanner
  }

  get bannerImage(): string {
    return this._data.bannerImage
  }

  set bannerImage(bannerImage: string) {
    this._data.bannerImage = bannerImage
  }

  get bannerImageHeight(): string {
    return this._data.bannerImageHeight
  }

  set bannerImageHeight(bannerImageHeight: string) {
    this._data.bannerImageHeight = bannerImageHeight
  }

  get logoImage(): string {
    return this._data.logoImage
  }

  set logoImage(logoImage: string) {
    this._data.logoImage = logoImage
  }

  get primaryColor(): string {
    return this._data.primaryColor
  }

  set primaryColor(primaryColor: string) {
    this._data.primaryColor = primaryColor
  }

  get addressRequired(): boolean {
    return this._data.addressRequired
  }

  set addressRequired(addressRequired: boolean) {
    this._data.addressRequired = addressRequired
  }

  get phoneRequired(): boolean {
    return this._data.phoneRequired
  }

  set phoneRequired(phoneRequired: boolean) {
    this._data.phoneRequired = phoneRequired
  }

  get namePasswordRequired(): boolean {
    return this._data.namePasswordRequired
  }

  set namePasswordRequired(namePasswordRequired: boolean) {
    this._data.namePasswordRequired = namePasswordRequired
  }

  get serviceAgreement(): any {
    return this._data.serviceAgreement
  }

  set serviceAgreement(serviceAgreement: any) {
    this._data.serviceAgreement = serviceAgreement
  }

  get serviceAgreementText(): string {
    return this._data.serviceAgreementText
  }

  set serviceAgreementText(serviceAgreementText: string) {
    this._data.serviceAgreementText = serviceAgreementText
  }

  get serviceAgreementExternalLink(): string {
    return this._data.serviceAgreementExternalLink
  }

  set serviceAgreementExternalLink(serviceAgreementExternalLink: string) {
    this._data.serviceAgreementExternalLink = serviceAgreementExternalLink
  }

  get checkoutCopy(): string {
    return this._data.checkoutCopy
  }

  set checkoutCopy(checkoutCopy: string) {
    this._data.checkoutCopy = checkoutCopy
  }

  get videoUrl(): string {
    return this._data.videoUrl
  }

  set videoUrl(videoUrl: string) {
    this._data.videoUrl = videoUrl
  }
}
