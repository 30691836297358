







































import Vue from 'vue'
import Loader from '@/components/common/Loader.vue'
import { UserPurchaseService, getContactId } from '../../services'
import { PurchaseHistory } from '../../helper/interfaces'
import { currency } from '@/helper/constants'
export default Vue.extend({
  components: {
    Loader
  },
  data() {
    return {
      errorInFetch: false,
      fetching: false,
      purchases: [] as PurchaseHistory[]
    }
  },
  mounted() {
    this.fetchUserPurchases()
  },
  methods: {
    async fetchUserPurchases() {
      try {
        this.fetching = true
        this.errorInFetch = false

        const contactId = getContactId()

        const purchaseData = await UserPurchaseService.findAll({
          contact_id: contactId
        })

        let purchases = [] as PurchaseHistory[]

        for (let i = 0; i < purchaseData.length; i++) {
          const p: any = purchaseData[i]

          const {
            products,
            offer,
            paymentType,
            amount,
            checkoutFrom,
            purchaseCurrency,
            purchase_id: id,
            createdAt: date
          } = p

          const { offer_name: offerName, currency: offerCurrency } = offer
          let currencySymbol = '$'

          if (checkoutFrom === 'funnel') {
            const currencyData = currency[purchaseCurrency?.toUpperCase()]
            currencySymbol = currencyData ? currencyData.symbol : '$'
          } else if (offerCurrency) {
            const currencyData = currency[offerCurrency]
            currencySymbol = currencyData ? currencyData.symbol : '$'
          }

          const data: PurchaseHistory[] = products.map(productData => {
            const { product_name: productName } = productData
            const price = amount ? `${currencySymbol}${amount}` : 'Free'

            return {
              id,
              offerName,
              productName,
              date,
              price
            } as PurchaseHistory
          })

          purchases = [...purchases, ...data]
        }

        this.purchases = purchases
      } catch (error) {
        console.error('Error while fetching purchase data --> ', error)
        this.errorInFetch = true
      } finally {
        this.fetching = false
      }
    }
  },
  filters: {
    getReadableDate(date: string) {
      const d = new Date(date)
      return d.toDateString()
    }
  }
})
