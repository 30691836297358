

































































import Vue from 'vue'
import LoginService from '../services/LoginService'
import ButtonLoader from '@/components/ui-components/ButtonLoader.vue'
export default Vue.extend({
  components: { ButtonLoader },
  data() {
    return {
      email: '',
      errorMsg: '',
      passwordReset: false,
      loader: false
    }
  },
  computed: {
    brandLogoUrl(): string {
      return this.$store.state.logoUrl
    }
  },
  methods: {
    async login() {
      try {
        this.loader = true

        const hostName: string =
          this.$route.query.domain && this.$route.query.domain !== null
            ? this.$route.query.domain
            : window.location.hostname

        const { data } = await LoginService.recovery({
          email: this.email.trim(),
          hostName: hostName.trim()
        })
        this.passwordReset = true
      } catch (error) {
        this.errorMsg = 'Something went wrong while resetting customer password'
      } finally {
        this.loader = false
      }
    }
  }
})
