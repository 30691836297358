








































import Vue from 'vue'
import Navigation from '@/components/common/Navigation.vue'
import ProductCard from '@/components/product/ProductCard.vue'
import LibraryShimmer from '@/components/common/LibraryShimmer.vue'
import { UserPurchaseService } from '@/services'
import Product from '../models/Product'
import UniversalSearch from '@/components/common/UniversalSearch.vue'

export default Vue.extend({
  components: { Navigation, ProductCard, LibraryShimmer, UniversalSearch },
  data() {
    return {
      products: [] as Product[],
      fetching: false,
      showSearchModal: false
    }
  },
  mounted() {
    this.$bus.$on('openSearchModal', this.openSearchModal)
    this.fetchUserProducts()
    // fetchAndSaveBrandData(this)
  },
  methods: {
    async fetchUserProducts() {
      this.fetching = true

      try {
        this.products = await UserPurchaseService.getAllProducts()
      } catch (error) {
        console.error('Error while fetching products --> ', error)
      } finally {
        this.fetching = false
      }
    },
    openSearchModal(value: boolean) {
      this.showSearchModal = value
    }
  }
})
