













































































import Vue from 'vue'
import axios from 'axios'
import { userAvatarImage } from '../../helper/constants'
import { MediaUploadService, getUserId, getContactId } from '../../services'
import config from '../../config'
import Loader from '@/components/common/Loader.vue'
import UserAvatar from '@/components/common/UserAvatar.vue'
import LoginService from '../../services/LoginService'

export default Vue.extend({
  components: {
    Loader,
    UserAvatar
  },
  computed: {
    saveDisabled(): boolean {
      return this.saving || this.uploading || !this.fullName || !this.email
    }
  },
  data() {
    return {
      errorInFetch: false,
      fetching: false,
      saving: false,
      email: '',
      fullName: '',
      userPic: userAvatarImage,
      uploading: false,
      file: {} as any,
      saveBtnText: 'Save',
      avatarChanged: false
    }
  },
  mounted() {
    this.fetchUserDetails()
  },
  methods: {
    async fetchUserDetails() {
      try {
        this.errorInFetch = false
        this.fetching = true

        const contactId = getContactId()
        if (!contactId) {
          throw new Error(`Invalid contact id ${contactId}`)
        }

        const { data } = await axios.get(
          config.mainServerUrl + `/contact/membership/${contactId}`
        )
        const { email, fullName } = data

        this.email = email
        this.fullName = fullName

        const avatar = this.$store.getters.userAvatar

        if (avatar) {
          this.userPic = avatar
        }
      } catch (error) {
        this.errorInFetch = true
        console.error('Error while fetching contact details --> ', error)
      } finally {
        this.fetching = false
      }
    },
    async save() {
      try {
        this.saving = true
        this.saveBtnText = 'Saving..'
        const contactId = getContactId()

        await axios.post(
          config.mainServerUrl + `/contact/membership/${contactId}`,
          {
            email: this.email,
            fullName: this.fullName
          }
        )
        await LoginService.updateEmail({ email: this.email })

        if (this.avatarChanged) {
          await LoginService.setAvatar(this.userPic)
        }

        this.saveBtnText = 'Saved!'

        this.$store.commit('SAVE_AVATAR', this.userPic)
      } catch (error) {
        console.error('Error while updating details --> ', error)
        this.saveBtnText = 'Error while saving!!'
      } finally {
        setTimeout(() => {
          this.saving = false
          this.saveBtnText = 'Save'
        }, 2000)
      }
    },
    openFileDialog() {
      this.$refs.profilePicInput.click()
    },
    fileSelected(event: any) {
      console.log('got file selected event --> ', event)
      const { files } = event.target

      if (files && files.length) {
        const file = files[0]
        this.loadFile(file)
        this.uploadFile(file)
      }
    },
    loadFile(file: any) {
      this.file = file
      this.readFile(file)
    },
    readFile(file: any) {
      const reader = new FileReader()

      reader.onload = e => {
        if (e && e.target && e.target.result) {
          this.userPic = e.target.result
        }
      }

      reader.readAsDataURL(file)
    },
    async uploadFile() {
      if (!this.file) return

      this.uploading = true
      try {
        const payload: any = { file: this.file, type: 'image' }

        payload['filename'] = getUserId()

        payload['folder'] = 'profile-pics'

        const { url } = await MediaUploadService.uploadFile(payload)

        this.userPic = url
        this.avatarChanged = true
      } catch (error) {
        console.error('error while uploading --> ', error)
      } finally {
        this.uploading = false
      }
    }
  }
})
