















































import Vue from 'vue'
export default Vue.extend({
  props: {
    productId: String,
    productTitle: String,
    categoryId: String,
    categoryTitle: String,
    postId: String,
    postTitle: String
  }
})
