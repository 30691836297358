

































import Vue from 'vue'
import { defaultPosterImage } from '../../helper/constants'
export default Vue.extend({
  props: {
    productId: {
      type: String
    },
    productTitle: {
      type: String
    },
    posterImage: {
      type: String
    }
  },
  data() {
    return {
      defaultPosterImage
    }
  },
  filters: {
    truncateText(text: string): string {
      const MAX_LENGTH = 30
      if (text.length < MAX_LENGTH) return text

      return text.slice(0, MAX_LENGTH) + '...'
    }
  }
})
