






import Vue from 'vue'
import { userAvatarImage } from '../../helper/constants'
export default Vue.extend({
  props: {
    src: {
      type: String,
      default: userAvatarImage
    }
  }
})
