import http from '../../http-common'

class CheckoutService {
  get endpoint() {
    return `offers`
  }
  findById(id: string) {
    return http.get(`${this.endpoint}/${id}`)
  }
  checkout(id: string, params: object) {
    return http.post(`${this.endpoint}/checkout/${id}`, params)
  }
  // purchase(params: object) {
  //   return http.post(`${this.endpoint}`, params)
  // }
  // freeCheckout(params: object) {
  //   return http.post(`${this.endpoint}/free`, params)
  // }
  leadTrack(params: object) {
    return http.post(`track/lead`, params)
  }
  checkIfContactPurchasedOffer(params: {
    offerId: string
    contactId: string
    locationId: string
  }) {
    return http.post(`${this.endpoint}/check-contact-offer-purchase`, params)
  }
  setupIntent(params: { locationId: string; offerId: string }) {
    return http.post(`${this.endpoint}/setup-intent`, params)
  }
}

export default new CheckoutService()
