




























import Vue from 'vue'
import Progress from '@/components/common/Progress.vue'
import { defaultPosterImage } from '../../helper/constants'

export default Vue.extend({
  props: {
    category: {
      type: Object,
      required: true
    },
    progress: {
      type: Number
    },
    notAvailable: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Progress
  },
  data() {
    return {
      defaultPosterImage
    }
  }
})
