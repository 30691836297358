var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"playlist-queue-container flex flex-col bg-gray-800 md:block mt-3  md:mt-0 lg:mt-0 xl:mt-0"},[_c('div',{staticClass:"playlist-info flex justify-between text-white p-5 bg-gray-800 h-16 w-full"},[_c('span',{staticClass:"playlist-title font-semibold text-sm"},[_vm._v(_vm._s(_vm.categoryTitle))]),_c('span',{staticClass:"playlist-length text-sm"},[_vm._v(_vm._s(_vm.playlist.length)+" Lesson"+_vm._s(_vm.playlist.length > 1 ? 's' : ''))])]),_c('div',{staticClass:"playlist-queue overflow-auto flex-1",style:({ height: ("calc(100% - " + (_vm.nextCategory ? '8rem' : '4rem') + ")") })},_vm._l((_vm.playlist),function(categoryTreeNode,index){return _c('router-link',{key:categoryTreeNode.id,staticClass:"playlist-item p-2 w-full flex items-center cursor-pointer",class:_vm.activePost === categoryTreeNode.id ? 'bg-gray-700' : '',attrs:{"tag":"div","to":{
        name: 'post-overview',
        params: _vm.getRouteParams(categoryTreeNode)
      }}},[_c('span',{staticClass:"playlist-item-count mr-2 text-sm",class:_vm.activePost === categoryTreeNode.id ? 'text-blue-500' : 'text-white'},[_vm._v(_vm._s(index + 1))]),_c('img',{staticClass:"playlist-item-image object-contain h-16 w-24",attrs:{"src":_vm._f("replaceBucketUrlWithCdnUrl")(categoryTreeNode.node.posterImage ||
            _vm.defaultPosterImage),"alt":"Post"}}),_c('span',{staticClass:"playlist-item-title flex-1 text-white text-sm pl-2"},[_vm._v(_vm._s(categoryTreeNode.node.title))]),(_vm.nodeIsCompleted(categoryTreeNode))?_c('img',{staticClass:"h-5",attrs:{"src":_vm.checkIcon,"alt":"Completed"}}):_vm._e()])}),1),(_vm.nextCategory)?_c('div',{staticClass:"next-category-button-container bg-gray-800 p-3 justify-center flex h-16"},[_c('router-link',{staticClass:"rounded px-4 md:px-5 xl:px-4 py-3 md:py-4 xl:py-3 bg-gray-700 hover:bg-gray-600 text-sm text-white font-semibold leading-tight shadow-md",attrs:{"id":"next-category-button","to":{
        name: 'category-overview',
        params: { id: _vm.productId, category_id: _vm.nextCategory }
      },"tag":"button"}},[_vm._v("Next Category")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }