




















































































































import Vue from 'vue'
import CategoryPostBreadcrumbs from '@/components/common/CategoryPostBreadcrumbs.vue'
import VideoPlayer from '@/components/product/VideoPlayer.vue'
import PostQueue from '@/components/product/PostQueue.vue'
import InstructorCard from '@/components/product/InstructorCard.vue'
import PostMaterialsCard from '@/components/product/PostMaterialsCard.vue'
import PostShimmer from '@/components/common/PostShimmer.vue'
import GoToNextPost from '@/components/product/GoToNextPost.vue'
import {
  CategoryService,
  ProductService,
  PostService,
  UserPostCompletionService,
  getUserId,
  UserProductTrackingService,
  UserPurchaseService,
  VideoService
} from '../services'
import Post from '../models/Post'
import { Instructor } from '../models/ProductCustomization'
import {
  extractInstructorInfoFromCustomization,
  createCategoryPostTree,
  computeNextPost
} from '../helper'
import Category, { CategoryVisibility } from '../models/Category'
import Product from '../models/Product'
import CategoryTreeNode, {
  CategoryTreeNodeType
} from '../models/CategoryTreeNode'
import { userAvatarImage, lockIcon } from '../helper/constants'
import { adminMode, fetchCat } from '@/helper/permission.helper'
import config from '@/config'
import { generateToken } from '../../http-common'

export default Vue.extend({
  props: {
    product: {
      type: Object
    },
    instructor: {
      type: Object
    },
    productStarted: Boolean
  },
  components: {
    CategoryPostBreadcrumbs,
    VideoPlayer,
    PostQueue,
    PostMaterialsCard,
    InstructorCard,
    PostShimmer,
    GoToNextPost
  },
  computed: {
    productId(): string {
      return this.$route.params.id
    },
    categoryId(): string {
      return this.$route.params.category_id
    },
    postId(): string {
      return this.$route.params.post_id
    },
    postCompletionButtonText(): string {
      if (this.disablePostCompletionButton) {
        return this.postMarkedAsCompleted
          ? 'Marking as Incomplete'
          : 'Completing..'
      } else {
        return this.postMarkedAsCompleted ? 'Completed' : 'Mark As Complete'
      }
    },
    isAdmin() {
      return adminMode()
    },
    markCompletedBtnCss() {
      if (this.disablePostCompletionButton || this.isAdmin) {
        return 'cursor-not-allowed'
      } else if (this.postMarkedAsCompleted) {
        return 'hover:bg-green-500 text-green-700 border-green-500 hover:text-white hover:border-transparent'
      }
      return 'hover:bg-blue-500 text-blue-700 border-blue-500 hover:text-white hover:border-transparent'
    },
    videoOptions() {
      const { locationId } = fetchCat()
      let transcodeUrl
      if (this.activePost.video.hdTranscoded) {
        transcodeUrl = `${config.transcodingURL}/${locationId}/videos/${this.activePost.video.id}_,53,32,16,00k.mp4.urlset/master.m3u8?token=${this.token}`
      } else {
        transcodeUrl = `${config.transcodingURL}/${this.$route.params.location_id}/videos/${this.activePost.video.id}_,32,16,9,6,00k.mp4.urlset/master.m3u8?token=${this.token}`
        VideoService.reTranscodeVideo(this.activePost.video.id)
      }
      return {
        autoplay: false,
        controls: true,
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        controlBar: {
          seekToLive: false,
          pictureInPictureToggle: false
        },
        sources: [
          {
            src: transcodeUrl,
            type: 'application/x-mpegURL'
          }
        ]
      }
    }
  },
  async mounted() {
    await this.fetchCategoryAndPosts(this.categoryId)
    this.token = await generateToken()
    await this.fetchPost(this.postId)
    this.checkIfPostCompleted(this.postId)
    this.fetchCompletedPosts()
    this.fetchNextCategory(this.categoryId)
  },
  data() {
    return {
      category: {} as Category,
      activePost: {} as Post,
      lockedPost: {} as Post,
      fetchingPost: false,
      fetchingPostCompletionStatus: true,
      postMarkedAsCompleted: false,
      disablePostCompletionButton: false,
      completedPostIds: new Set() as Set<string>,
      playlist: [] as Array<CategoryTreeNode>,
      nextCategoryId: '',
      lockIcon,
      nextPost: null,
      token: ''
    }
  },
  methods: {
    resetData() {
      this.nextPost = null
    },
    navigatePost() {
      this.$router.push({
        name: 'post-overview',
        params: {
          id: this.productId,
          category_id: this.lockedPost.categoryId,
          post_id: this.lockedPost.id
        }
      })
    },
    async fetchCategoryAndPosts(categoryId: string) {
      this.category = await UserPurchaseService.getCategory(categoryId, {
        published_posts: true,
        product_id: this.productId
      })

      if (this.category.isLocked) {
        await this.fetchedLockedPost(this.category.lockedBy)
      }

      if (!this.category.available) {
        this.$router.push({
          name: 'product-overview',
          params: {
            id: this.productId
          }
        })
      }

      // const subCategories = await CategoryService.findAll({
      //   parent_category: categoryId,
      //   published_posts: true,
      //   visibility: CategoryVisibility.published
      // })

      const subCategories = await UserPurchaseService.getCategories({
        product_id: this.productId,
        parent_category: categoryId
      })

      const postNodes = this.category.posts.map(
        (p: Post) =>
          new CategoryTreeNode(p, CategoryTreeNodeType.Post, categoryId)
      )
      const subCategoryNodes = subCategories.map(
        (c: Category) =>
          new CategoryTreeNode(c, CategoryTreeNodeType.Category, categoryId)
      )

      this.playlist = [...postNodes, ...subCategoryNodes].sort(
        (a: CategoryTreeNode, b: CategoryTreeNode) =>
          a.sequenceNo - b.sequenceNo
      )
    },
    async fetchedLockedPost(postId: string) {
      this.lockedPost = await PostService.findById(postId)
    },
    async fetchPost(postId: string) {
      this.fetchingPost = true
      try {
        this.activePost = await PostService.findById(postId)
      } catch (error) {
        console.error('Error while fetching post --> ', error)
      } finally {
        this.fetchingPost = false
      }
    },
    async togglePostCompletion() {
      try {
        this.disablePostCompletionButton = true

        if (this.postMarkedAsCompleted) {
          await this.markPostAsIncomplete()
          this.postMarkedAsCompleted = false
        } else {
          if (!this.productStarted) {
            UserProductTrackingService.trackProductStart(this.productId)
          }

          await this.markPostAsCompleted()
          this.postMarkedAsCompleted = true
          await this.fetchNextPost()
        }
      } catch (error) {
        console.error('error while changing post completion status --> ', error)
      } finally {
        this.disablePostCompletionButton = false
      }
    },
    async fetchNextPost() {
      // fetch categories, and for category tree
      const categories = await UserPurchaseService.getCategories({
        product_id: this.productId
      })

      const categoryTree = createCategoryPostTree(null, categories)

      // fetch user post completion data
      const userId = getUserId()

      const completedResp = await UserPostCompletionService.findAll({
        product_id: this.productId,
        user_id: userId
      })

      const postIdsCompleted = completedResp.reduce(
        (agg: Set<string>, completed: any) => {
          const { postId } = completed
          agg.add(postId)
          return agg
        },
        new Set()
      )

      const nextPost = computeNextPost(categoryTree, postIdsCompleted)
      this.nextPost = nextPost ? nextPost.node : null
    },
    async checkIfPostCompleted(postId: string) {
      try {
        this.fetchingPostCompletionStatus = true

        const data = await UserPostCompletionService.checkIfPostCompleted(
          postId
        )

        if (data && data.length) {
          this.activePost.postCompletedRef = data[0].id
          this.postMarkedAsCompleted = true
        } else {
          this.postMarkedAsCompleted = false
        }
      } catch (error) {
        console.error('Error while checking if post is completed --> ', error)
      } finally {
        this.fetchingPostCompletionStatus = false
      }
    },
    async markPostAsCompleted() {
      const data = await UserPostCompletionService.markPostAsCompleted({
        postId: this.postId,
        productId: this.productId
      })

      this.activePost.postCompletedRef = data.id

      const completedPosts = this.completedPostIds
      completedPosts.add(this.activePost.id)
      this.completedPostIds = new Set(completedPosts)
    },
    async markPostAsIncomplete() {
      if (this.activePost.postCompletedRef) {
        await UserPostCompletionService.markPostAsIncomplete(
          this.activePost.postCompletedRef
        )
      }

      this.activePost.postCompletedRef = null

      const completedPosts = this.completedPostIds
      completedPosts.delete(this.activePost.id)
      this.completedPostIds = new Set(completedPosts)
    },
    async fetchCompletedPosts() {
      const userId = getUserId()

      const completedResp = await UserPostCompletionService.findAll({
        product_id: this.productId,
        user_id: userId
      })

      this.completedPostIds = completedResp.reduce(
        (agg: Set<string>, completed: any) => {
          const { postId } = completed
          agg.add(postId)
          return agg
        },
        new Set()
      )
    },
    async fetchNextCategory(categoryId: string) {
      const nextCategoryId = await CategoryService.findNextCategory(categoryId)
      console.log('found next category --> ', nextCategoryId)
      this.nextCategoryId = nextCategoryId
    }
  },
  watch: {
    async postId(newValue) {
      this.resetData()
      await this.fetchPost(newValue)
      await this.checkIfPostCompleted(newValue)
    },
    async categoryId(newValue) {
      this.resetData()
      this.fetchCategoryAndPosts(newValue)
      this.fetchNextCategory(newValue)
    }
  }
})
