













import Vue from 'vue'
export default Vue.extend({
  props: {
    percentCompleted: {
      type: Number,
      default: 0
    },
    showPercentage: {
      type: Boolean,
      default: false
    }
  }
})
