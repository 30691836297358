






















import Vue from 'vue'
import PostMaterial, { PostMaterialType } from '../../models/PostMaterial'
import { pdfIcon, imageIcon, docIcon } from '../../helper/constants'
export default Vue.extend({
  props: {
    materials: {
      type: Array
    }
  },
  computed: {
    postMaterials(): PostMaterial[] {
      if (!this.materials) return [] as PostMaterial[]

      return this.materials
        .slice()
        .sort((a: any, b: any) => a.sequenceNo - b.sequenceNo) as PostMaterial[]
    }
  },
  methods: {
    getIcon(materialType: string) {
      switch (materialType) {
        case PostMaterialType.pdf: {
          return pdfIcon
        }
        case PostMaterialType.image: {
          return imageIcon
        }
        default: {
          return docIcon
        }
      }
    }
  }
})
