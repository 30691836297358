



























import Vue from 'vue'
export default Vue.extend({
  props: { options: Array, value: String, disabled: String },
  computed: {
    selectedValue: {
      get(): string {
        return this.value
      },
      set(value: string) {
        this.$emit('input', value)
      }
    }
  }
})
