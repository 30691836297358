



































import Vue from 'vue'
import { defaultVideoPlaceHolder, playBtnImage } from '../../helper/constants'
import videojs from 'video.js'
import { VideoService } from '../../services'
import { TRANSCODING_STATUS } from '../../models/Video'
import videojsqualityselector from 'videojs-hls-quality-selector'
import 'videojs-contrib-quality-levels'

export default Vue.extend({
  props: {
    video: Object,
    loading: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      videoPlaceholderImg: defaultVideoPlaceHolder,
      playBtnImage,
      loadVideo: false,
      player: null
    }
  },
  async mounted() {
    await this.getTranscodingStatus()
    this.initializeVideo()
  },
  methods: {
    initializeVideo() {
      this.player = videojs(
        this.$refs.videoPlayer,
        this.options,
        function onPlayerReady() {
          console.log('Playing on transcoded video')
        }
      )
      this.player.on('error', () => {
        console.log(this.player.error())
        this.transcodingStatus = TRANSCODING_STATUS.REMAINING
        this.initializeVideo()
        VideoService.checkTranscodingError(this.$route.params.post_id)
      })
      this.player.ready(() => {
        this.player.tech().on('usage', (e) => {
          if(e.name === 'vhs-rendition-blacklisted') {
            this.transcodingStatus = TRANSCODING_STATUS.REMAINING
            this.initializeVideo()
            this.player.play()
            VideoService.checkTranscodingError(this.$route.params.id)
          }
        })
      })
      if (this.transcodingStatus !== TRANSCODING_STATUS.COMPLETED) {
        console.log(this.transcodingStatus)
        this.player.src({
          type: 'video/mp4',
          src: this.$options.filters.replaceBucketUrlWithCdnUrl(this.video.url)
        })
        console.log('Playing video directly from bucket')
      }
      this.player.hlsQualitySelector = videojsqualityselector
      this.player.hlsQualitySelector({
        displayCurrentQuality: true
      })
    },
    async getTranscodingStatus() {
      const params: object = {
        post_id: this.$route.params.post_id
      }
      const response = await VideoService.findAll(params)
      this.transcodingStatus = response[0]?.transcodingStatus
    }
  }
})
