






































import Vue from 'vue'
import { defaultPosterImage } from '@/helper/constants'

export default Vue.extend({
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      defaultPosterImage
    }
  }
})
