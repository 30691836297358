










































import Vue from 'vue'
import Navigation from '@/components/common/Navigation.vue'

export default Vue.extend({
  components: {
    Navigation
  }
})
