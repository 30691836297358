
















































































import Vue from 'vue'
import CheckoutForm from '@/components/checkout/CheckoutForm.vue'
import CheckoutService from '@/services/CheckoutService'
import VideoPlayer from '@/components/common/VideoPlayer.vue'
import {
  defaultPosterImage,
  defaultBannerPlaceHolder
} from '@/helper/constants'
import Offer, { OfferVisibility } from '@/models/Offer'
import Loader from '@/components/common/Loader.vue'
import postscribe from 'postscribe'

export default Vue.extend({
  components: { CheckoutForm, VideoPlayer, Loader },
  metaInfo() {
    const { customCss, customJs } = this.offer
    const meta = {}

    if (customCss) {
      meta['style'] = [
        {
          cssText: customCss,
          type: 'text/css',
          vmid: 'offer-custom-css'
        }
      ]
    }

    if (customJs) {
      meta['script'] = [
        {
          innerHTML: customJs,
          type: 'text/javascript',
          vmid: 'offer-custom-js',
          body: true,
          async: true
        }
      ]
    }

    return meta
  },
  data() {
    defaultPosterImage
    defaultBannerPlaceHolder
    return {
      defaultPosterImage,
      defaultBannerPlaceHolder,
      offer: {} as Offer,
      builder: '' as any,
      location: {},
      preview: '',
      loader: false
    }
  },
  async mounted() {
    this.builder = this.$route.query.builder
    this.preview = this.$route.query.preview
    this.fetchOfferData()
  },
  computed: {
    bannerStyle(): object {
      if (
        this.offer.OfferCustomization &&
        this.offer.OfferCustomization.bannerImage
      ) {
        return {
          minHeight:
            this.offer.OfferCustomization.bannerImageHeight + 'px' || '150px',
          backgroundImage: `url(${this.$options.filters.replaceBucketUrlWithCdnUrl(
            this.offer.OfferCustomization.bannerImage
          )})`
        }
      } else {
        return {
          backgroundImage: `url(${defaultBannerPlaceHolder})`,
          minHeight: '150px'
        }
      }
    },
    disableActions(): object {
      return {
        pointerEvents: this.builder ? 'none' : 'all'
      }
    },
    adminMode(): boolean {
      return this.builder || this.preview
    }
  },
  methods: {
    async fetchOfferData() {
      try {
        this.loader = true
        const response = await CheckoutService.findById(this.$route.params.id)
        if (response?.status === 200) {
          const { data } = response
          this.offer = new Offer(data.offer)
          this.location = data.location
          console.log(this.builder, this.preview, this.offer.visibility)
          this.loader = false

          if (
            this.offer.visibility === OfferVisibility.draft &&
            !this.builder &&
            !this.preview
          ) {
            this.loader = false

            this.$router.push({ path: '/404' })
          }

          if (this.offer.customHeader) {
            postscribe(`#offerCustomHeader`, this.offer.customHeader)
          }

          if (this.offer.customFooter) {
            postscribe(`#offerCustomFooter`, this.offer.customFooter)
          }
        } else {
          throw new Error('Something went wrong')
        }
      } catch (error) {
        this.loader = false
        this.$router.push({ path: '/404' })
      }
    }
  }
})
