

















import Vue from 'vue'
declare global {
  interface Window {
    Stripe: any
  }
}
import CheckoutService from '@/services/CheckoutService'

export default Vue.extend({
  props: { location: Object, contact: Object, locationId: String, offerId: String },
  data() {
    return {
      stripeAPIToken: '',
      stripe: {} as any,
      elements: {} as any,
      card: {} as any,
      errMsg: '',
      disableCheckoutBtn: false
    }
  },
  mounted() {
    this.stripeAPIToken = this.location.stripe
    this.configureStripe()
  },
  methods: {
    configureStripe() {
      const options = this.location.stripeAccountId ? { stripeAccount: this.location.stripeAccountId } : null;
      this.stripe = window.Stripe(this.stripeAPIToken, options)
      this.elements = this.stripe.elements()
      this.card = this.elements.create('card', { hidePostalCode: true })

      this.card.mount('#card-element')
    },
    async purchase() {
      try {
        this.disableCheckoutBtn = true

        const { data: setupIntentResponse } = await CheckoutService.setupIntent(
          {
            locationId: this.locationId,
            offerId: this.offerId
          }
        )

        let paymentMethod

        const {
          client_secret: clientSecret,
          payment_method: setupIntentPaymentMethod
        } = setupIntentResponse

        if (!clientSecret) {
          throw new Error(
            'Client secret missing! Payment setup failed, try again!'
          )
        }

        if (setupIntentPaymentMethod) {
          paymentMethod = setupIntentPaymentMethod
        }

        const result = await this.stripe.confirmCardSetup(clientSecret, {
          payment_method: {
            card: this.card,
            billing_details: {
              name: this.contact.full_name_lower_case || this.contact.first_name
            }
          }
        })

        if (result.error) {
          throw new Error(result.error.message)
        }

        if (result.setupIntent) {
          paymentMethod = result.setupIntent.payment_method
        }

        const payload = {
          fingerprint: this.contact.fingerprint,
          locationId: this.contact.location_id,
          productType: 'offer',
          paymentMethodId: paymentMethod,
          clientSecret,
          checkoutType: 'purchase'
        }

        try {
          const {
            data: { ct, previewUrl }
          } = await CheckoutService.checkout(this.$route.params.id, payload)
          this.$emit('order-successful', { ct, previewUrl })
          this.disableCheckoutBtn = false
        } catch (err) {
          throw new Error(err?.response?.data?.msg)
        }
      } catch (error) {
        this.errMsg = error
        this.disableCheckoutBtn = false
      }
    }
  }
})
