








































































import Vue from 'vue'
import { checkIcon, defaultPosterImage } from '@/helper/constants'
import CategoryTreeNode, {
  CategoryTreeNodeType
} from '../../models/CategoryTreeNode'
import Category from '../../models/Category'
import Post from '../../models/Post'

export default Vue.extend({
  props: {
    productId: {
      type: String
    },
    categoryId: {
      type: String
    },
    categoryTitle: {
      type: String
    },
    playlist: {
      type: Array
    },
    activePost: {
      type: String
    },
    completedPosts: {
      type: Set,
      default: new Set()
    },
    nextCategory: {
      type: String
    }
  },
  data() {
    return {
      checkIcon,
      defaultPosterImage
    }
  },
  methods: {
    nodeIsCompleted(entity: CategoryTreeNode): boolean {
      if (entity.type === CategoryTreeNodeType.Post) {
        return this.completedPosts.has(entity.id)
      } else {
        const category = entity.node as Category

        if (!category.posts) return false

        const completedPosts = category.posts.filter((p: Post) =>
          this.completedPosts.has(p.id)
        )

        return completedPosts.length === category.posts.length
      }
    },
    getRouteParams(categoryTreeNode: CategoryTreeNode): object {
      if (categoryTreeNode.type === CategoryTreeNodeType.Post) {
        return {
          id: this.productId,
          category_id: this.categoryId,
          post_id: categoryTreeNode.id
        }
      } else {
        const category = categoryTreeNode.node as Category
        const firstPost = category.posts[0]
        return {
          id: this.productId,
          category_id: categoryTreeNode.id,
          post_id: firstPost.id
        }
      }
    }
  }
})
