
























































import Vue from 'vue'
import PostListItem from './PostListItem.vue'
import { CategoryTreeNodeType } from '../../models/CategoryTreeNode'

export default Vue.extend({
  name: 'CategoryPostTree',
  components: {
    PostListItem
  },
  props: {
    categoryTree: {
      type: Array
    },
    completedPosts: {
      type: Set,
      default: () => new Set()
    },
    level: {
      type: Number,
      default: 0
    },
    available: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    nodeIsPost(type: CategoryTreeNodeType) {
      return type === CategoryTreeNodeType.Post
    }
  }
})
