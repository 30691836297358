



































































































































































































































import Vue from 'vue'
import firebase from 'firebase'
import 'firebase/auth'
import { userAvatarImage } from '../../helper/constants'
import UserAvatar from '@/components/common/UserAvatar.vue'
import { adminMode } from '../../helper/permission.helper'

export default Vue.extend({
  components: {
    UserAvatar
  },
  props: {
    logo: {
      type: String
    }
  },
  data() {
    return {
      open: false,
      openSettings: false
    }
  },
  computed: {
    brandLogoUrl(): string {
      return this.logo || this.$store.state.logoUrl
    },
    userImage(): string {
      return this.$store.getters.userAvatar || userAvatarImage
    },
    isAdmin() {
      return adminMode()
    }
  },
  methods: {
    signOut() {
      this.$cookies.remove('cat')
      if (localStorage) {
        try {
          localStorage.removeItem('cat')
        } catch (error) {
          // patch
        }
      }
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push({ name: 'login' })
        })
    },
    openModal() {
      this.$bus.$emit('openSearchModal', true)
    }
  }
})
