


















import Vue from 'vue'
import { defaultPosterImage, checkIcon } from '../../helper/constants'
export default Vue.extend({
  props: {
    post: {
      type: Object
    },
    categoryId: {
      type: String
    },
    completed: {
      type: Boolean,
      default: false
    },
    available: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    posterImage(): string {
      return this.post.posterImage || defaultPosterImage
    },
    productId(): string {
      return this.post.productId
    }
  },
  data() {
    return {
      checkIcon
    }
  }
})
