


















import Vue from 'vue'
import Progress from '@/components/common/Progress.vue'

export default Vue.extend({
  components: {
    Progress
  },
  props: {
    completed: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    }
  },
  computed: {
    percentCompleted(): number {
      if (this.completed && this.total) {
        return Math.floor((this.completed * 100) / this.total)
      }

      return 0
    }
  }
})
